import Head from "next/head";
import Header from "../components/organisms/common/Header";
import Footer from "../components/organisms/common/Footer";
import AppStorage from "../components/AppStorage";

const ErrorContent = () => {
  return (
    <section className="container grid--default has-align-top hero--default pb-10">
      <article className="text-left">
        <h1 className="text-lg lg:pt-5 lg:maxw-lg">
          Hmm, looks like this page doesnt exist
        </h1>
        <p className="lg:maxw-mds lg:mb-2 md:mb-2">
          Are you a <strong>customer</strong> looking to make an appointment?
          Please check that you&apos;ve used the right Booking Page link. If you
          can’t spot any errors, contact the business directly.
        </p>

        <p className="lg:maxw-mds lg:mb-2 md:mb-2">
          Are you a <strong>Setmore user</strong> looking for your own Booking
          Page? Head to Apps &amp; Integrations to confirm its URL. If you still
          need a hand, start a chat with us, call
          <a
            className="fw-600"
            href="tel:+1 877-9
          89-7413"
          >
            +1 877-989-7413
          </a>{" "}
          or email{" "}
          <a className="text-brandcolor fw-600" href="mailto:help@setmore.com">
            help@setmore.com
          </a>
          .
        </p>
        <p className="lg:maxw-mds">We&apos;re here to help 24/7.</p>
      </article>{" "}
      <figure className="layered-images-wrap pos-rel lg:maxw-lg md:mt-5">
        <picture>
          <img
            loading="lazy"
            src="https://assets.setmore.com/website/v2/images/error-page/setmore-bg-404-page.svg"
            alt="Setmore booking page setup"
            className="bg-layer"
          />

          <img
            loading="lazy"
            src="https://assets.setmore.com/website/v2/images/error-page/woman-painter-curly-hair@2x.png"
            alt="A woman painter with curly hair"
            className="fg-layer md:neg-left"
          />
        </picture>
      </figure>
    </section>
  );
};

export async function getStaticProps({ locale }) {
  let allData = {};
  // Fetch data for respective pages
  const headerResponse = await fetch(
    `${process.env.JSON_PATH}/${locale}/common/header.json`
  );
  const footerResponse = await fetch(
    `${process.env.JSON_PATH}/${locale}/common/footer.json`
  );

  // Convert
  allData.header = await headerResponse.json();
  allData.footer = await footerResponse.json();
  // Wrap
  const header = {
    header: allData.header,
  };
  const footer = {
    footer: allData.footer,
  };
  // Combine all data
  const data = await {
    ...header,
    ...footer,
  };
  // Return to the component
  return {
    props: {
      data,
    },
  };
}
export default function Error({ data }) {
  return (
    <>
      <AppStorage pageName="Error Page" />
      <Head>
        <title>404 Page Not Found</title>
        <meta name="yandex-verification" content="cff3a4bed1cce307" />
        <meta
          property="og:image"
          content="https://assets.setmore.com/website/v2/images/error-page/setmore-og-404-page.png"
        />
      </Head>
      <Header data={data.header} />
      <ErrorContent />
      <Footer data={data.footer} />
    </>
  );
}
